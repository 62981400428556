<template>
  <div id="pompe-diagnosis">
    <collapsible-container 
      id="pompeDiagnosis"
      title="Pompe Diagnosis"
      :isToggled="isToggled"
      @toggle-collapse="isToggled = !isToggled"
    >
      <!-- Date of Pompe Diagnosis -->
      <detail-text
        label="Date of Pompe Diagnosis"
        :text="data.dateOfPompeDiagnosis ? formatDateNormal(data.dateOfPompeDiagnosis) : null"
      />
      <!-- Method of pompe diagnosis -->
      <detail-text
        label="Method of Pompe Diagnosis"
        :text="data.methodOfPompeDiagnosis ? getMethodPompeText(data.methodOfPompeDiagnosis) : null"
      />

      <hr />
      <!-- First Enzyme Assay -->
      <enzyme-assay 
        section-type="first"
        :data="data" 
      />
      <hr />
      <!-- Second Enzyme Assay -->
      <enzyme-assay
        v-if="data.methodOfPompeDiagnosis === 'twoEnzymeAssay'"
        section-type="second"
        :data="data"  
      />
      <!-- Genotype -->
      <genotype
        v-if="data.methodOfPompeDiagnosis === 'oneEnzymeAssayAndGenotype'"
       :data="data" 
      />

    </collapsible-container>
  </div>
</template>

<script>
import EnzymeAssay from './EnzymeAssay.vue';
import Genotype from './Genotype.vue';

export default {
  components: {
    EnzymeAssay,
    Genotype,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    isToggled: false,
    statusList: {
      ongoing: 'On Going',
      stopped: 'Stopped',
      unknown: 'Unknown'
    }
  }),
  methods: {
    getMethodPompeText(value) {
      const object = {
        twoEnzymeAssay: 'Two Enzyme Assay',
        oneEnzymeAssayAndGenotype: 'One Enzyme Assay and Genotype',
        oneEnzymeAssay: 'One Enzyme Assay',
      };

      return object[value];
    },
  }
};
</script>

<style></style>
