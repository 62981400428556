<template>
  <div id="genotype">
    <h6> Patient Genotype </h6>
    <detail-text
      class="mt-2"
      label="Patient Genotype"
      :text="data.patientGenotype"
    />
    <detail-text
      class="mt-2"
      label="Date of Genotype Diagnosis"
      :text="data.dateOfGenotypeAssay ? formatDateNormal(data.dateOfGenotypeAssay) : null"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style>

</style>