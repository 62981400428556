<template>
  <div id="concomitant-therapy">
    <collapsible-container 
      id="concomitantTherapy"
      title="Concomitant Therapy"
      :isToggled="isToggled"
      @toggle-collapse="isToggled = !isToggled"
    >
      <div 
        v-for="(item, idx) in data" 
        :key="idx"
      >
        <h6 class="mb-2">Therapy #{{ idx + 1 }}</h6>
        <!-- concomitantTherapyStatus -->
        <detail-text
          label="Medication Status"
          :text="statusList[item.concomitantTherapyStatus]"
        />
        <!-- Medication Category -->
        <detail-text
          label="Medication Category"
          :text="medications[item.medicationCategory]"
        />
        <!-- Other Medication Category -->
        <detail-text
          v-if="item.medicationCategory === 'other'"
          label="Other Medication Category"
          :text="item.nameOtherMedication"
        />
        <!-- Start Date -->
        <detail-text
          v-if="(item.concomitantTherapyStatus === 'ongoing' && item.concomitantTherapyStatus !== 'unknown') || item.concomitantTherapyStatus === 'stopped'"
          label="Start Date"
          :text="item.concomitantTherapyStartDate"
        />
        <!-- End Date -->
        <detail-text
          v-if="item.concomitantTherapyStatus !== 'ongoing' && item.concomitantTherapyStatus !== 'unknown'"
          label="End Date"
          :text="item.concomitantTherapyEndDate"
        />
      </div>
    </collapsible-container>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    isToggled: false,
    statusList: {
      ongoing: 'On Going',
      stopped: 'Stopped',
      unknown: 'Unknown'
    },
    medications: {
      immunidepressants: "Immunidepressants",
      diuretics: "Diuretics",
      treatmentForGIReflux: "Treatment for GI reflux",
      narcoticAnalgetics: "Narcotic Analgetics",
      treatmentForHeartFailure: "Treatment for heart failure",
      bronchodilators: "Bronchodilators",
      statins: "Statins",
      steroids: "Steroids",
      betablockers: "Betablockers",
      oralBetaAgonists: "Oral beta agonists",
      nonNarcoticAnalgetics: "Non-narcotic analgetics",
      other: "Other",
    }
  }),
};
</script>

<style></style>
