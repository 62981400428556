<template>
  <div id="assessment-overview">
    <!-- Primary Endpoints -->
    <PrimaryEndpoints
      v-if="content.assessmentOverview"
      name="primary-endpoints"
      :first-column-values="options.primaryEndpointsOpt"
      :number-of-column="content.assessmentOverview.primaryEndpoints.length"
      :table-values="content.assessmentOverview.primaryEndpoints"
    />

    <!-- Other Endpoints -->
    <OtherEndpoints
      v-if="content.assessmentOverview"
      name="other-endpoints"
      :first-column-values="options.otherEndpointsOpt"
      :number-of-column="content.assessmentOverview.otherEndpoints.length"
      :table-values="content.assessmentOverview.otherEndpoints"
    />
  </div>
</template>

<script>
import PrimaryEndpoints from "./PrimaryEndpoints/Index";
import OtherEndpoints from "./OtherEndpoints/Index";

export default {
  name: "AssessmentOverview",
  components: {
    PrimaryEndpoints,
    OtherEndpoints,
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>
