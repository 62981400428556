<template>
  <div id="medical-history">
    <collapsible-container 
      id="medicalHistory"
      title="Medical History"
      :isToggled="isToggled"
      @toggle-collapse="isToggled = !isToggled"
    >
      <div 
        v-for="(item, idx) in data" 
        :key="idx"
      >
        <h6 class="mb-2">Medical History #{{ idx + 1 }}</h6>
        <!-- Disease Description -->
        <detail-text
          label="Disease Description"
          :text="item.diseaseDescription"
        />
        <!-- Medical History Status -->
        <detail-text
          label="Medical History Status"
          :text="statusList[item.medicalHistoryStatus]"
        />
        <!-- Date of diagnosis -->
        <detail-text
          v-if="item.medicalHistoryStatus !== 'unknown'"
          label="Date of Diagnosis"
          :text="item.dateOfDiagnosis"
        />
        <!-- Date of diagnosis resolution -->
        <detail-text
          v-if="item.medicalHistoryStatus === 'stopped' && item.medicalHistoryStatus !== 'unknown'"
          label="Date of Resolution"
          :text="item.dateOfResolution"
        />
      </div>
    </collapsible-container>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    isToggled: false,
    statusList: {
      ongoing: 'On Going',
      stopped: 'Stopped',
      unknown: 'Unknown'
    }
  }),
};
</script>

<style></style>
