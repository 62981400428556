<template>
  <div id="overview-page">
    <b-overlay :show="loading">
      <custom-breadcrumb class="mt-2" :items="breadcrumbs" />

      <div class="custom-container">
        <h4>Overview Page</h4>
        <h6 class="text-muted"> Patient ID : {{ patientRef }} </h6>
        <hr />

        <b-tabs>
          <b-tab
            v-for="(item, idx) in tabs"
            :key="idx"
            :id="item.page"
            :title="item.label"
            :active="item.page === selectedTab"
            @click="selectTab(item.page)"
          />

          <br />
          <component 
            :is="selectedTab" 
            :loading="loading" 
            :content="assessment.content"
            :options="assessment.options"
          />
        </b-tabs>

        <div class="text-center buttons mt-3">
          <button
            v-if="$route.params.tab === 'assessment'"
            class="btn btn-sm btn-pompe-primary mr-2" @click.prevent="newVisit"
          >
            New Visit
          </button>
          <button class="btn btn-sm btn-secondary" @click="$router.push('/')">
            Go Back
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import patientOverview from "./patientOverview/Index.vue";
import assessmentOverview from "./Assessment/Index";

import { mapMutations, mapState } from "vuex";

import { getPatientRef } from '../../../helper/getPatientRef';

import moment from "moment";

export default {
  components: {
    patientOverview,
    assessmentOverview,
  },
  data: () => ({
    breadcrumbs: [],
    loading: false,
    notification: {
      type: "",
      message: "",
      isActive: false,
    },
    assessment: {
      content: {},
      options: []
    },
    patientRef: '',
  }),
  computed: {
    ...mapState({
      selectedTab: (state) => state.overviewPage.selectedTab,
      tabs: (state) => state.overviewPage.tabs,
      content: (state) => state.overviewPage.content,
      options: (state) => state.overviewPage.options,
    }),
  },
  async mounted() {
    const { data } = await this.getPatientRef(this.$route.params.id);
    this.patientRef = data.ref || '-';

    this.setSelectedTab(this.$route.params.tab);
    if (this.$route.params.tab === 'assessmentOverview') {
      this.loading = true;
      this.startAssessmentOverviewProcess(this.$route.params.id);
    } else {
      this.getData();
    }

    this.constructBreadcrumb();
  },
  methods: {
    getPatientRef,
    ...mapMutations({
      setSelectedTab: "overviewPage/setSelectedTab",
      setData: "overviewPage/setData",
      setVisitData: "overviewPage/setVisitData",
      setOptions: "overviewPage/setOptions",
      setTabs: "overviewPage/setTabs",
    }),
    constructBreadcrumb() {
      const activeCrumbs = {
        ...(this.selectedTab === 'patientOverview' ? {
          key: 'patientOverview',
          text: 'Patient Overview',
          active: true,
        } : {
          key: 'assessmentDiagnosis',
          text: 'Assessment Diagnosis',
          active: true,
        })
      }

      this.breadcrumbs = [
        { 
          key: 'home', 
          text: 'Home',
          action: () => window.location.href = '/web',
        },
        { ...activeCrumbs }
      ];
    },
    async selectTab(tab) {
      if (this.selectedOverviewTab !== tab) {
        this.setSelectedTab(tab);
        this.constructBreadcrumb();
        await this.$router.push({
          name: "patientOverview",
          params: { tab: tab, id: this.$route.params.id },
        });
      }

      if (tab === 'assessmentOverview') {
        this.loading = true;
        this.startAssessmentOverviewProcess(this.$route.params.id);
      } else {
        this.getData();
      }
    },
    handleAssessmentOverviewStartSuccess(response) {
      this.assessment.content = response.data.content;
      this.assessment.options = response.data.options;

      this.assessment.content.assessmentOverview.primaryEndpoints.map(el => {
        el.dateOfVisit = this.formatDateNormal(el.dateOfVisit);
      })

      this.assessment.content.assessmentOverview.otherEndpoints.map(el => {
        el.dateOfVisit = this.formatDateNormal(el.dateOfVisit);
      })

      this.loading = false;
    },
    changeDateFormat(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    handleAssessmentOverviewStartFailed() {
      this.setSelectedTab('patientOverview');
      this.$router.push({
        name: 'patientOverview',
        params: {
          tab: 'patientOverview',
          id: this.$route.params.id
        }
      })

      this.loading = false;
    },
    getData() {
      this.loading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.startProcessOverview +
            `?businessKey=${this.$route.params.id}`,
          {},
          (response) => {
            this.setData(response.data.content);
            this.setOptions(response.data.options);
            this.setTabs(response.data.nav);
          },
          (e) => console.log(e)
        )
        .then(() => {
          this.loading = false;
        });
    },
    newVisit() {
      this.loading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.startProcessNewVisit +
            `?businessKey=${this.$route.params.id}`,
          {},
          (response) =>
            (window.location.href = `/web/add-new-patient/${response.data.page}/${this.$route.params.id}/overview-visit`),
          (e) => console.log(e)
        )
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./Index.scss";
</style>
