<template>
  <div id="visit-list">
    <h6> List of Vist </h6>
    <pompe-table 
      v-bind="config"
      :data="data"
      pagination-alignment="left"
    >
      <template #action="{ row }">
         <a
            class="anchor-link"
            href="javascript:void(0)"
            @click.prevent="startEditVisit(row.id)"
          >
            <b-icon icon="pencil" aria-hidden="true"></b-icon>
            Edit Visit
          </a>
      </template>
    </pompe-table>
  </div>
</template>

<script>
import PompeTable from '@/components/reusable/PompeTable.vue';

import { mapMutations } from 'vuex';

export default {
  name: 'visit-table-list',
  components: {
    PompeTable
  },
  props: {
    config: {
      type: Object,
      default: () => [{}]
    },
    data: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    ...mapMutations({
      setTitle: "creation/setTitle",
    }),
  }
}
</script>

<style>

</style>