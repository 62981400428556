<template>
  <div id="surgical-procedures">
    <collapsible-container
      id="surgicalProcedures"
      title="Surgical Procedures"
      :isToggled="isToggled"
      @toggle-collapse="isToggled = !isToggled"
    >
      <div v-if="data.length">
        <div v-for="(item, idx) in data" :key="idx">
          <h6 class="mb-2">Procedure #{{ idx + 1 }}</h6>

          <detail-text label="Procedure" :text="item.surgicalProcedure" />
          <detail-text label="Date" :text="item.surgicalProcedureDate" />
          <detail-text label="Side" :text="item.side" />
          <detail-text label="Prior to pompe therapy" :text="item.procedurePriorToPompeTherapy" />

          <hr>
        </div>
      </div>

      <div v-else>
        <span class="h6 text-muted"> No Surgical Procedures Data </span>
      </div>
    </collapsible-container>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isToggled: false,
  }),
};
</script>

<style></style>
